import { useEffect } from 'react';
import axios from 'axios';

function App() {

  useEffect(()=>{
    axios.post('https://emr-api-dev.abcintelligence.ai/admissions/43544/auth', {
      animalName: "Beans",
      postalCode: "L1H 7K4"
    }, {
      withCredentials: true,
      headers: {
        'abc-emr-clinic-id': 1,
      }
    })
    .then(({data, headers})=>{
      console.log('auth response:');
      console.log(data);
      axios.get('https://emr-api-dev.abcintelligence.ai/admissions/43544', {
        withCredentials: true,
        headers: {
          'abc-emr-clinic-id': 1,
        }
      })
      .then(({data2, headers2})=>{
        console.log('get data response:');
        console.log(data2);
      })
      .catch(error2=>{
        console.log('get data error:');
        console.log(error2);
      });
    })
    .catch(error=>{
      console.log('auth error:');
      console.log(error);
    });
  }, []);

  return (
    <div>
      
    </div>
  );
}

export default App;
